<template>
  <div class="message">{{message}}</div>

</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";
export default {
  name: "TrackAndTrace",
  props: ["crateid"],
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {

      registryid: 3,
      processdefinitionid: 5,
      //   registryid: 1,
      //   processdefinitionid: 6,
      message: 'Please wait ...'

    };
  },
  mounted() {
    this.init();


  }
  ,
  methods: {
    async init() {
      if (location.hostname.includes('localhost')) {
        this.registryid = 1,
          this.processdefinitionid = 6;
      }

      EventBus.$emit('openLoader');



      try {

        let result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes?search=TrackTrace${this.crateid}Crate&page=0&per_page=10&status=InProgress`);

        if (result.data.processs.length > 0) {

          let process = result.data.processs[0];

          result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${process.process}/process_steps?page=0&per_page=10`);

          let process_steps = result.data.process_steps;

          let process_step = process_steps.find(ss => typeof ss.process_step == 'undefined' || ss.status == 'Created');

          if (!process_step) {
            this.message = 'An unexpected error occured';
            return;
          }

          let data = { crateid: this.crateid, registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid: process.process, process_steps: process_steps, extrinsic: process.extrinsic };

          let attestor = process_step.attestors.find(attestor => attestor.did == this.currentUser.did);
          if (!attestor) {
            // this.message = 'You are not an attestor on the current step';
            console.log(data);
            this.$router.push({ name: 'summary', params: data });
            return;
          }



          switch (process_step.order) {
            case 0:
              this.$router.push({ name: 'pickup', params: data });
              console.log('Step1');
              break;
            case 1:
              this.$router.push({ name: 'receive', params: data });
              console.log('Step2');
              break;
            case 2:
              this.$router.push({ name: 'dispatch', params: data });
              console.log('Step3');
              break;
            case 3:
              this.$router.push({ name: 'delivery', params: data });
              console.log('Step4');
              break;
            default: console.log('unexpected order');
          }


        } else {

          let result = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes`, { name: `TrackTrace${this.crateid}Crate` });

          let processid = result.data.id;
          let extrinsic = result.data.extrinsic;

          result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${processid}/process_steps?page=0&per_page=10`);

          let process_steps = result.data.process_steps;

          let data = { crateid: this.crateid, registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid, process_steps, extrinsic: extrinsic };
          this.$router.push({ name: 'pickup', params: data });


        }



      } catch (e) {
        console.log(e);
        this.$toastr.e("Error getting data from server", 'Error');
        this.message = 'Error getting data from server';
        return null;
      } finally {
        EventBus.$emit('closeLoader');
      }

      //   
    },
    // async checkIfCrateAvailable() {
    //   let processStep;

    //   try {
    //     let result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/find_process_steps?page=0&per_page=10&process_status=InProgress&process_name_search=TrackTrace${this.crateid}Crate`);
    //     return result.data.process_steps.length == 0;
    //   } catch (e) {
    //     this.$toastr.e("Error getting data from server", 'Error');
    //     return null;
    //   }
    // },
    // async getProcessStep() {
    //   let processStep;

    //   try {
    //     let result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/find_process_steps?page=0&per_page=10&step_status=Created&process_name_search=TrackTrace${this.crateid}Crate`);
    //     return result.data;
    //   } catch (e) {
    //     this.$toastr.e("Error getting data from server", 'Error');
    //     return null;
    //   }
    // }

  }
}
</script>

<style scoped>
.message {
  text-align: center;
  margin-top: 50px;
}
</style>